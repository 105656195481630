import React, { useEffect, useState } from "react";
import customers from "../assets/cusotmers.png";
import plans from "../assets/plans.png";
import sms from "../assets/smssent.png";
import compaigns from "../assets/compainssent.png";
import customerGrowth from "../assets/customergrowth.png";
import revenue from "../assets/revenue.png";
import {
  PieChart,
  Tooltip,
  Pie,
  Cell,
  BarChart,
  Bar,
  Legend,
  LineChart,
  Line,
} from "recharts";
import axios from "axios";
import { apiBaseUrl } from "../utils/urls";
import { getToken } from "../utils/tokentUtils";

type DataType = {
  sms_incoming: any[];
  sms_outgoing: any[];
  outgoing: {
      id: string;
  };
  incoming: {
      id: string;
  };
  revenue_chart: {
      keys: any[];
      values: any[];
  };
  customer_growth: {
      keys: any[];
      values: any[];
  };
  sms_history: {
      delivered: number;
      undelivered: number;
  };
  sender_ids: SenderId[];
  report_count: number;
  campaigns_count: number;
};


type SenderId = {
  id: number;
  uid: string;
  user_id: number;
  currency_id: number;
  sender_id: string;
  status: string;
  price: string;
  billing_cycle: string;
  frequency_amount: number;
  frequency_unit: string;
  validity_date: string | null;
  transaction_id: string | null;
  payment_claimed: number;
  created_at: string;
  updated_at: string;
  description: string | null;
  entity_id: string | null;
  document: string | null;
};


const Dashboardview = () => {
  const [barGraphWidth, setBarWidth] = useState<any>(
    document.getElementById("barchart")?.clientWidth
  );
  const [lineGraphWidth, setLineWidth] = useState<any>(
    document.getElementById("outsms")?.clientWidth
  );

  useEffect(() => {
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setBarWidth(document.getElementById("barchart")?.clientWidth);
      setLineWidth(document.getElementById("outsms")?.clientWidth);
    }, 1000);
  }, []);

  function handleResize() {
    const lineChartContainer = document.getElementById("outsms");
    const barChartContainer = document.getElementById("barchart");
    setBarWidth(barChartContainer?.clientWidth);
    setLineWidth(lineChartContainer?.clientWidth);
    // Code to handle screen size changes
  }

  // using pie chart and their data
  
  const COLORS = ["#67c2ed", "#e12d2d"];

  // usig bar graph for customer growth and their data.
 

  // using line graph for showing outgoing sms and their data.
  

  const [customers, setCustomers] = useState(0);
  const [plans, setPlans] = useState(0);
  const [dashboardData, setDashboardData] = useState<DataType>({
    sms_incoming: [],
    sms_outgoing: [],
    outgoing: { id: "" },
    incoming: { id: "" },
    revenue_chart: { keys: [], values: [] },
    customer_growth: { keys: [], values: [] },
    sms_history: { delivered: 0, undelivered: 0 },
    sender_ids: [],
    report_count: 0,
    campaigns_count: 0,
});


  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        orderBy: "id",
        orderDir: "DESC",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/customers/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      let adminDashboard = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/dashboard`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }
      };
      axios
      .request(adminDashboard)
      .then((response) => {
        const data = response?.data;
        if (data?.status === "success") {
          setDashboardData((prevData) => ({
            ...prevData,
            ...data.data, // Ensure data structure matches DataType
          }));
          console.log("api data", data);
          console.log("dash data", dashboardData);
        } else {
          console.log(`Getting Customers Number error ${data?.message}`);
        }
      })
      .catch((error) => {
        console.log(`Getting Customers Number error ${error?.message}`);
      });
    
      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          console.log("statistics data", data);
          if (data?.status === "success") {
            setCustomers(data?.data?.recordsFiltered);
          } else {
            console.log(`Getting Customers Number error ${data?.message}`);
          }
        })
        .catch((error) => {
          console.log(`Getting Customers Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);
  useEffect(() => {
    try {
      const token = getToken();
      let data = JSON.stringify({
        length: 10,
        orderBy: "id",
        orderDir: "DESC",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${apiBaseUrl}/admin/plans/search`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          //   console.log(response.data);
          const data = response?.data;
          console.log("plans data", data);
          if (data?.status === "success") {
            setPlans(data?.data?.recordsFiltered);
          } else {
            console.log(`Getting plans Number error ${data?.message}`);
          }
        })
        .catch((error) => {
          console.log(`Getting plans Number error ${error?.message}`);
        });
    } catch (error) {}
  }, []);

  const piedata = [
    { name: "Delivered", value: dashboardData.sms_history.delivered },
    { name: "Failed", value: dashboardData.sms_history.undelivered },
  ];

  const bargraphdata : {name:any, value: any}[] = [];
  const outGoingSms  : {name:any, value: any}[] = [];

  // using line graph for showing incomming sms and their data.
  const inCommingSms = [];

  useEffect(()=>{
    dashboardData.customer_growth.keys.map((item:any, index)=>{
      bargraphdata.push({
        name: item,
        value: dashboardData.customer_growth.values[index]
      });
    });

    dashboardData.sms_incoming.map((item:any, index)=>{
      outGoingSms.push({
        name: "",
        value: item
      })
    })

  }, [dashboardData])

  return (
    <div className="w-full mt-[20px] xl:pr-[40px] 2xl:pr-0 px-[12px] md:px-0  ">
      <div className="bg-white pt-[18px] pl-[18px] pb-[23.5px] rounded-[2px] border-4 w-full pr-[10px] md:pr-0">
        <p className="text-[16px] leading-[25.6px] text-[#5F6368] font-normal pb-[15px]">
          Statistics
        </p>
        <div className="grid md:grid-cols-4 grid-cols-2 gap-[30px] ">
          <div className="flex items-center gap-[14px] cursor-pointer relative">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#0092D7]  font-normal">
                {customers}
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                Customers
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#E6F7FF] rounded-full flex items-center justify-center">
              <img src={customers} alt="" />
            </div>
            <div className=" absolute top-0 right-[30%] h-[100%] bg-[#EDEDED] w-[1px] hidden md:block"></div>
          </div>
          <div className="flex items-center gap-[14px] cursor-pointer relative">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#24E073]  font-normal">
                {plans}
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                Plans
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#E5F8ED] rounded-full flex items-center justify-center">
              <img src={plans} alt="" />
            </div>
            <div className="absolute top-0 right-[30%] h-[100%] bg-[#EDEDED] w-[1px] hidden md:block"></div>
          </div>
          <div className="flex items-center gap-[14px] cursor-pointer relative">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#E12D2D]  font-normal">
                {dashboardData?.report_count}
              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                SMS Sent
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#FCEAEA] rounded-full flex items-center justify-center">
              <img src={sms} alt="" />
            </div>
            <div className=" absolute top-0 right-[30%] h-[100%] bg-[#EDEDED] w-[1px] hidden md:block"></div>
          </div>
          <div className="flex items-center gap-[14px] cursor-pointer">
            <div className="w-[106px]">
              <p className="text-[21px] leading-[33.6px] text-[#F79236]  font-normal">
              {dashboardData.campaigns_count}

              </p>
              <p className="text-[14px] leading-[22.4px] font-normal text-[#5F6368]">
                Campaigns Sent
              </p>
            </div>
            <div className="w-[40px] h-[40px] bg-[#FFF2E6] rounded-full flex items-center justify-center">
              <img src={compaigns} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <Statistics customers plans sms compaigns /> */}
      {/* <---------------------------------------------------------------------------------- */}

      <div className="md:flex   mt-[32px] gap-[34.52px] w-full ">
        <div className="flex  basis-[33%] bg-white  pt-[14px] pl-[17.74px] h-[355px] rounded-[2px] border-4 relative">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal">
            Customer Growth
          </h2>
          <div
            className="w-[98%] h-[221px] absolute bottom-[15%]"
            id="barchart"
          >
            {/* using bar graph for showing customer growth */}
            <BarChart
              width={barGraphWidth}
              height={300}
              data={bargraphdata}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip />
              <Legend />
              <Bar dataKey="CustomerGrowth" fill="#67c2ed" />
            </BarChart>
          </div>
        </div>

        <div className="flex flex-col items-center bg-white h-[355.5px] basis-[33%] rounded-[5px] relative  border-4 ">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[18px] pl-[18px]">
            SMS Report
          </h2>

          <div className="w-full flex items-center justify-center">
            <PieChart width={330} height={330}>
              {/* using pie chart for showing sms report */}
              <Pie
                dataKey="value"
                isAnimationActive={true}
                data={piedata}
                cx={180}
                cy={140}
                outerRadius={100}
                innerRadius={2}
                label
              >
                {piedata.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>

          <div className="flex items-center justify-between border-t-[2px] border-[#EDEDED] pt-[4px]  absolute bottom-[0%] w-full">
            <div className="text-center pl-[54.5px]">
              <p className="text-[#AEAEAE] text-[13px]  leading-[15.23px] font-bold">
                DELIVERED
              </p>
              <h2 className="text-[#5F6368] text-[29.33px] leading-[34.38px] font-normal mt-[10px]">
              {dashboardData.sms_history.delivered}
              </h2>
            </div>
            <div className="pr-[72px] text-center">
              <p className="text-[#AEAEAE] text-[13px]  leading-[15.23px] font-bold">
                FAILED
              </p>
              <h2 className="text-[#5F6368] text-[29.33px] leading-[34.38px] font-normal mt-[10px]">
              {dashboardData.sms_history.undelivered}

              </h2>
            </div>
          </div>
        </div>

        {/* <---------------------------------------------------------------------------------- */}

        <div className="flex flex-col items-center bg-white h-[355.5px] basis-[33%] rounded-[5px] relative  border-4">
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[14px] pl-[17.74px]">
            Revenue this month
          </h2>
          <BarChart
              width={barGraphWidth}
              height={300}
              data={bargraphdata}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip />
              <Legend />
              <Bar dataKey="Revenue of this month" fill="#67c2ed" />
            </BarChart>
        </div>
      </div>
      {/* <---------------------------------------------------------------------------------- */}
      <div className="flex gap-2 flex-col 2xl:flex-row w-full ">
        <div className=" flex-1  bg-white mt-[37px] h-[186.36px] rounded-[2px] border-4 ">
          {/* line graph for outgoing sms  */}
          <div id="outsms">
            <LineChart
              width={lineGraphWidth}
              height={100}
              data={outGoingSms}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <Tooltip />
              <Line
                type="monotone"
                dataKey="sms"
                stroke="#67c2ed"
                strokeWidth={4}
              />
            </LineChart>
          </div>
          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[18px] pl-[27.57px]">
            Outgoing SMS History of this Month
          </h2>
        </div>
        {/* <---------------------------------------------------------------------------------- */}
        <div className="flex-1 bg-white mt-[37px] h-[186.36px] rounded-[2px] mb-[52.28px] border-4">
          {/* line graph for incomming sms  */}

          <LineChart
            width={lineGraphWidth}
            height={100}
            data={inCommingSms}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <Tooltip />
            <Line
              type="monotone"
              dataKey="sms"
              stroke="#67c2ed"
              strokeWidth={4}
            />
          </LineChart>

          <h2 className="text-[#5F6368] text-[16px] leading-[18.75px] font-normal pt-[18px] pl-[27.57px]">
            incoming SMS History of this Month
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Dashboardview;
